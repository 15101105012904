import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../../../general/PaginationData";
import BankImg from "@/assets/images/banks.svg";
import BanksFilter from "./BanksFilter";

export default class Bank {
  defaultImg = BankImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.bankToken = "";
    this.bankNameAr = "";
    this.bankNameEn = "";
    this.bankNotes = "";
    this.bankArchiveStatus = "";
  }
  fillData(data) {
    this.bankToken = data.bankToken;
    this.bankNameAr = data.bankNameAr;
    this.bankNameEn = data.bankNameEn;
    this.bankNotes = data.bankNotes;
    this.bankArchiveStatus = data.bankArchiveStatus;
  }

  async getAllBanks(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new BanksFilter()
  ) {
    try {
      let response = await axios.get(
        `/Banks/GetAllBanks?language=${language}&userAuthorizeToken=${userAuthorizeToken}&bankArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfBanks(
    language,
    userAuthorizeToken,
    filterData = new BanksFilter()
  ) {
    return await axios.get(
      `/Banks/GetDialogOfBanks?language=${language}&userAuthorizeToken=${userAuthorizeToken}&bankArchiveStatus=false&filterStatus=true&textSearch=${filterData.textSearch}`
    );
  }

  async getBankDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Banks/GetBankDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&bankToken=${this.bankToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateBank(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      bankToken: this.bankToken,
      bankNameEn: this.bankNameEn,
      bankNameAr: this.bankNameAr,
      bankNotes: this.bankNotes,
    };

    try {
      if (this.bankToken == "" || this.bankToken == undefined) {
        return await axios.post(`/Banks/AddBank`, data);
      } else {
        return await axios.post(`/Banks/UpdateBank`, data);
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveBank(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      bankToken: this.bankToken,
    };

    try {
      return await axios.post(`/Banks/ArchiveBank`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
