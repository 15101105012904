<template>
  <div v-if="banksData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("nameAr") }}</th>
            <th>{{ $t("nameEn") }}</th>
            <th>{{ $t("notes") }}</th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsUpdate
                )
              "
            >
              {{ $t("edit") }}
            </th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsArchive
                )
              "
            >
              {{ $t("delete") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(bank, index) in banksData" :key="bank.bankToken">
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>{{ bank.bankNameAr }}</td>
            <td>{{ bank.bankNameEn }}</td>
            <td>
              {{ bank.bankNotes ? bank.bankNotes : $t("notFound") }}
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsUpdate
                )
              "
            >
              <router-link
                :to="{
                  name: 'BankEdit',
                  params: {
                    bankToken: bank.bankToken,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsArchive
                )
              "
            >
              <button
                v-b-modal.BankDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setBankData(bank)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";

export default {
  name: "BanksTable",
  mixins: [privilegeMixin],
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["banksData", "bankData", "pagination"],
  methods: {
    setBankData(bankData) {
      this.$emit("setBankData", bankData);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
