import PaginationData from "@/models/general/PaginationData";
import Bank from "./Bank";
import BanksFilter from "./BanksFilter";

export default class Banks {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.banksData = [];
    this.bank = new Bank();
    this.filterData = new BanksFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.banksPagination);
    this.banksData = data.banksPagination.banksData;
  }
}
