<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="banks.banksData != undefined && banks.banksData.length != 0">
      <BanksTable
        :banksData="banks.banksData"
        :bankData="banks.bank"
        v-on:setBankData="banks.bank.fillData($event)"
        :pagination="banks.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="banks.pagination"
        v-on:changePage="changePage"
      />
      <BankDelete :bankData="banks.bank" v-on:refresh="getAllBanks(1)" />
    </div>

    <BankFloatBtns
      :theFilterData="banks.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import BanksTable from "@/components/settings/settingsOther/banks/BanksTable.vue";
import BankDelete from "@/components/settings/settingsOther/banks/BankDelete.vue";
import BankFloatBtns from "@/components/settings/settingsOther/banks/BankFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import Banks from "@/models/settings/settingsOther/banks/Banks";

export default {
  name: "Banks",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    BanksTable,
    BankDelete,
    BankFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      banks: new Banks(),
    };
  },
  methods: {
    changePage(page) {
      this.banks.pagination.selfPage = page;
      this.getAllBanks();
    },
    search(data) {
      this.banks.filterData.fillData(data);
      this.banks.banksData = [];
      this.getAllBanks();
    },
    async getAllBanks() {
      this.isLoading = true;
      try {
        const response = await this.banks.bank.getAllBanks(
          this.language,
          this.userAuthorizeToken,
          this.banks.pagination,
          this.banks.filterData
        );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.banks.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.banks.banksData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.banks.banksData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.banks.banksData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.banks.banksData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllBanks();
  },
};
</script>

<style lang="scss"></style>
